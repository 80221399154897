<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
    </ol>
    <h1 class="page-header">
      สรุปผลการนับคะแนน ส.อบจ.
      <small>รายงานผลอย่างไม่เป็นทางการ</small>
    </h1>
    <panel>
      <template slot="header">
        <h4 class="panel-title">
          ผลคะแนนเลือกตั้ง ส.อบจ.สุรินทร์
          <font class="text-red-darker">(Refresh ข้อมูลในอีก {{ timerCount }} วินาที)</font>
        </h4>
        <div class="me-10">
          <b-form-checkbox v-model="auto_area" name="check-button" switch>เปลี่ยเขตการเลือกตั้งอัตโนมัติ</b-form-checkbox>
        </div>
      </template>
      <div class="row">
        <div class="col-1 mt-15">
          <a href="javascript:;" v-on:click.stop.prevent="getPreArea">
            <i class="icon-arrow-left fa-2x"></i>
          </a>
        </div>
        <div class="text-center col-10">
          <div class="form-inline justify-content-center">
            <div class="me-20">
              <b-img src="/assets/img/logo/surinpao-logo.png" rounded="circle" alt="Circle image" width="90px"></b-img>
            </div>
            <div>
              <h4>
                ผลคะแนนเลือกตั้ง ส.อบจ.สุรินทร์
                <br />
                เขตการเลือกตั้งที่ {{ area_no }} {{ districts[district_id] }}
              </h4>
              <h5 class="text-red-darker">
                รายงานผลอย่างไม่เป็นทางการ
                <br />
                ณ วันที่ {{ summary.curr_time }} น.
              </h5>
            </div>
          </div>
        </div>
        <div class="col-1 mt-15">
          <a href="javascript:;" v-on:click.stop.prevent="getNextArea">
            <i class="icon-arrow-right fa-2x"></i>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-5">
          <b-card body-class="p-0" class="mt-5">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th class="text-center" width="100px">หมายเลขผู้สมัคร</th>
                    <th class="text-center" width="1%"></th>
                    <th class="text-center">ชื่อตัว ชื่อสกุล</th>
                    <th class="text-center" width="1%">คะแนน</th>
                    <th class="text-center" width="1%">ลำดับที่</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="applicant in app_areas" :key="applicant.id">
                    <td width="100px" class="text-center">
                      <h1>{{ applicant.number }}</h1>
                    </td>
                    <td width="1%" class="with-img">
                      <img :src="applicant.img_path" class="img-rounded height-50" />
                    </td>
                    <td>
                      <h5 class="username text-ellipsis mt-10">{{ applicant.full_name }}</h5>
                    </td>
                    <td width="1%" class="text-right pt-10">
                      <h5 class="mt-10">{{ parseInt(applicant.vote).toLocaleString() }}</h5>
                    </td>
                    <td width="1%" class="text-center">
                      <h1 class="mt-5 text-red-darker">{{ parseInt(applicant.no).toLocaleString() }}</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-card bg-variant="light" sub-title="ผลคะแนนเลือกตั้ง ส.อบจ.สุรินทร์" class="mt-5">
            <apexchart type="bar" ref="chart" :options="mixedChart.options" :series="mixedChart.series"></apexchart>
          </b-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-7">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-6">
              <b-card bg-variant="light" class="mt-5">
                <apexchart type="pie" :options="pieUnit.options" :series="pieUnit.series"></apexchart>
              </b-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-6">
              <b-card bg-variant="light" class="mt-5">
                <apexchart type="pie" :options="pieElector.options" :series="pieElector.series"></apexchart>
              </b-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-6">
              <b-card bg-variant="light" class="mt-5">
                <apexchart type="pie" :options="pieCard.options" :series="pieCard.series"></apexchart>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
export default {
  name: 'ReportArea',
  data() {
    return {
      auto_area: true,
      area_id: 1,
      area_no: 1,
      refreshSec: 15,
      timerCount: 15,
      summary: {},
      app_areas: [],
      district_id: 3201,
      districts: {
        3201: 'อ.เมืองสุรินทร์',
        3202: 'อ.ชุมพลบุรี',
        3203: 'อ.ท่าตูม',
        3204: 'อ.จอมพระ',
        3205: 'อ.ปราสาท',
        3206: 'อ.กาบเชิง',
        3207: 'อ.รัตนบุรี',
        3208: 'อ.สนม',
        3209: 'อ.ศีขรภูมิ',
        3210: 'อ.สังขะ',
        3211: 'อ.ลำดวน',
        3212: 'อ.สำโรงทาบ',
        3213: 'อ.บัวเชด',
        3214: 'อ.พนมดงรัก',
        3215: 'อ.ศรีณรงค์',
        3216: 'อ.เขวาสินรินทร์',
        3217: 'อ.โนนนารายณ์',
      },
      elections: [
        { value: 1, text: 'เขตเลือกตั้งที่ 1 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 2, text: 'เขตเลือกตั้งที่ 2 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 3, text: 'เขตเลือกตั้งที่ 3 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 4, text: 'เขตเลือกตั้งที่ 4 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 5, text: 'เขตเลือกตั้งที่ 5 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 6, text: 'เขตเลือกตั้งที่ 6 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 7, text: 'เขตเลือกตั้งที่ 7 อ.เมืองสุรินทร์ จ.สุรินทร์' },
        { value: 8, text: 'เขตเลือกตั้งที่ 1 อ.ปราสาท จ.สุรินทร์' },
        { value: 9, text: 'เขตเลือกตั้งที่ 2 อ.ปราสาท จ.สุรินทร์' },
        { value: 10, text: 'เขตเลือกตั้งที่ 3 อ.ปราสาท จ.สุรินทร์' },
        { value: 11, text: 'เขตเลือกตั้งที่ 4 อ.ปราสาท จ.สุรินทร์' },
        { value: 12, text: 'เขตเลือกตั้งที่ 1 อ.ศีขรภูมิ จ.สุรินทร์' },
        { value: 13, text: 'เขตเลือกตั้งที่ 2 อ.ศีขรภูมิ จ.สุรินทร์' },
        { value: 14, text: 'เขตเลือกตั้งที่ 3 อ.ศีขรภูมิ จ.สุรินทร์' },
        { value: 15, text: 'เขตเลือกตั้งที่ 4 อ.ศีขรภูมิ จ.สุรินทร์' },
        { value: 16, text: 'เขตเลือกตั้งที่ 1 อ.สังขะ จ.สุรินทร์' },
        { value: 17, text: 'เขตเลือกตั้งที่ 2 อ.สังขะ จ.สุรินทร์' },
        { value: 18, text: 'เขตเลือกตั้งที่ 3 อ.สังขะ จ.สุรินทร์' },
        { value: 19, text: 'เขตเลือกตั้งที่ 1 อ.ท่าตูม จ.สุรินทร์' },
        { value: 20, text: 'เขตเลือกตั้งที่ 2 อ.ท่าตูม จ.สุรินทร์' },
        { value: 21, text: 'เขตเลือกตั้งที่ 1 อ.กาบเชิง จ.สุรินทร์' },
        { value: 22, text: 'เขตเลือกตั้งที่ 2 อ.กาบเชิง จ.สุรินทร์' },
        { value: 23, text: 'เขตเลือกตั้งที่ 1 อ.ชุมพลบุรี จ.สุรินทร์' },
        { value: 24, text: 'เขตเลือกตั้งที่ 2 อ.ชุมพลบุรี จ.สุรินทร์' },
        { value: 25, text: 'เขตเลือกตั้งที่ 1 อ.รัตนบุรี จ.สุรินทร์' },
        { value: 26, text: 'เขตเลือกตั้งที่ 2 อ.รัตนบุรี จ.สุรินทร์' },
        { value: 27, text: 'เขตเลือกตั้งที่ 1 อ.จอมพระ จ.สุรินทร์' },
        { value: 28, text: 'เขตเลือกตั้งที่ 2 อ.จอมพระ จ.สุรินทร์' },
        { value: 29, text: 'เขตเลือกตั้งที่ 1 อ.บัวเชด จ.สุรินทร์' },
        { value: 30, text: 'เขตเลือกตั้งที่ 1 อ.ลำดวน จ.สุรินทร์' },
        { value: 31, text: 'เขตเลือกตั้งที่ 1 อ.สนม จ.สุรินทร์' },
        { value: 32, text: 'เขตเลือกตั้งที่ 1 อ.สำโรงทาบ จ.สุรินทร์' },
        { value: 33, text: 'เขตเลือกตั้งที่ 1 อ.เขวาสินรินทร์ จ.สุรินทร์' },
        { value: 34, text: 'เขตเลือกตั้งที่ 1 อ.โนนนารายณ์ จ.สุรินทร์' },
        { value: 35, text: 'เขตเลือกตั้งที่ 1 อ.พนมดงรัก จ.สุรินทร์' },
        { value: 36, text: 'เขตเลือกตั้งที่ 1 อ.ศรีณรงค์ จ.สุรินทร์' },
      ],
      pieUnit: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#f59c1a', '#348fe2'],
          labels: ['ส่งผลแล้ว', 'ยังไม่ส่งผล'],
          title: {
            text: 'สถิติหน่วยนับที่ส่งผลการเลือกตั้ง (หน่วย)',
          },
        },
        series: [],
      },
      pieElector: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#fb5597', '#f59c1a'],
          labels: ['ผู้มาใช้สิทธิ', 'ผู้ไม่มาใช้สิทธิ'],
          title: {
            text: 'สถิติผู้มาใช้สิทธิเลือกตั้ง (ราย)',
          },
        },
        series: [],
      },
      pieCard: {
        options: {
          chart: {
            height: 365,
            type: 'pie',
          },
          dataLabels: {
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              opacity: 0.45,
            },
          },
          colors: ['#348fe2', '#00acac', '#8753de'],
          labels: ['บัตรดี', 'บัตรเสีย', 'ไม่ลงคะแนน'],
          title: {
            text: 'สถิติบัตรเลือกตั้ง (ใบ)',
          },
        },
        series: [],
      },
      mixedChart: {
        options: {
          chart: {
            height: 350,
            type: 'column',
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 0, 3],
          },
          colors: ['#276baa', '#00acac', '#f59c1a'],
          title: {
            text: 'ผลคะแนนไม่เป็นทางการ',
            align: 'left',
            offsetX: 150,
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: true,
              color: 'rgba(182, 194, 201, 0.5)',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: -1,
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#b6c2c9',
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#276baa',
              },
              labels: {
                style: {
                  color: '#276baa',
                },
              },
              title: {
                text: 'คะแนนเลือกตั้ง',
                style: {
                  color: '#276baa',
                },
              },
            },
          ],
        },
        series: [],
      },
    }
  },
  mounted() {
    this.getApplicantArea(this.area_id)
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
            if (this.timerCount == 0) {
              if (this.auto_area) {
                this.getNextArea()
              } else {
                this.getApplicantArea(this.area_id)
              }
              this.timerCount = this.refreshSec
            }
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    changeElectionArea() {
      this.getApplicantArea(this.area_id)
    },
    getSummaryArea() {
      let url = `election_units?t=get_summary_area&election_area_id=${this.area_id}`
      this.axios.get(url).then((res) => {
        this.summary = res.data
        this.pieUnit.series = [parseInt(res.data.send), parseInt(res.data.units) - parseInt(res.data.send)]
        this.pieElector.series = [res.data.comein ? parseInt(res.data.comein) : 0, parseInt(res.data.elector) - (res.data.comein ? parseInt(res.data.comein) : 0)]
        this.pieCard.series = [parseInt(res.data.good), parseInt(res.data.fail), parseInt(res.data.no_vote)]
      })
    },
    getPreArea() {
      if (this.area_id == 1) {
        this.getApplicantArea(36)
      } else {
        this.getApplicantArea(this.area_id - 1)
      }
    },
    getNextArea() {
      if (this.area_id == 36) {
        this.getApplicantArea(1)
      } else {
        this.getApplicantArea(this.area_id + 1)
      }
    },
    getApplicantArea(election_area_id) {
      let url = `applicant_areas?t=get_score&election_area_id=${election_area_id}`
      this.axios.get(url).then((res) => {
        this.app_areas = res.data.data
        this.district_id = res.data.district_id
        this.area_id = res.data.area_id
        this.area_no = res.data.area_no

        this.getSummaryArea()

        this.$refs.chart.updateSeries([
          {
            name: 'คะแนน',
            type: 'column',
            data: res.data.score,
          },
        ])
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: res.data.label,
          },
        })
      })
    },
  },
}
</script>

<style></style>
